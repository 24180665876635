"use client";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ReactDimmer } from "react-dimmer";
import { CSSProperties } from "styled-components";
import { trackEvent } from "../../lib/track";
import { INewTag, IPlugin } from "../../types";
import { StyledAside, StyledHeader, StyledMiniHeader } from "./header.styles";
import {
  AppsPopover,
  SupportPopover,
  CompanyPopover,
  FeaturesPopover,
} from "./menuPopovers/menuPopovers.comp";

import { RiArrowDownSLine } from "@react-icons/all-files/ri/RiArrowDownSLine";

import apps from "../../../apps.json";
import tags from "../../../tags.json";

import cn from "classnames";
import { usePathname } from "next/navigation";
import { companyLinks, support } from "@/helpers/cn-links";

interface IHeaderProps {
  style?: CSSProperties;
  isDeveloper?: boolean;
  className?: string;
  fixedPosition?: boolean;
  lightMode?: boolean;
  addBackground?: boolean;
}

export const Header = ({
  style = {},
  isDeveloper = false,
  className = "",
  fixedPosition = false,
  lightMode = false,
  addBackground = false,
}: IHeaderProps) => {
  const [isBrackets, setIsBrackets] = useState<boolean>(false);
  const [showAppsPopover, setShowAppsPopover] = useState<boolean>(false);
  const [showFeaturesPopover, setShowFeaturesPopover] =
    useState<boolean>(false);
  const [showResourcesPopover, setShowResourcesPopover] =
    useState<boolean>(false);
  const [showCompanyPopover, setShowCompanyPopover] = useState<boolean>(false);
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const pathname = usePathname();

  const tagList = (apps as IPlugin[]).reduce(
    (acc: string[], plugin: IPlugin) => {
      (plugin.newTags || []).forEach((tag) => {
        const wantedTag = (tags as INewTag[]).find((t) => t._id === tag._id);
        if (wantedTag && !acc.includes(wantedTag.name)) {
          acc.push(wantedTag.name);
        }
      });
      return acc;
    },
    []
  );

  const heightBreakpoint = 90;

  const headerClassnames = fixedPosition
    ? { "fix-position": fixedPosition }
    : {
      visible: isVisible && lastScrollY > heightBreakpoint,
    };

  const columnItems = tagList.splice(0, 3).map((tag: string) => {
    const wantedApps = (apps as IPlugin[])
      .filter((p: IPlugin) =>
        (p.newTags || []).map((t) => t.name).includes(tag)
      )
      .slice(0, 5);

    return { title: tag, apps: wantedApps };
  });

  function getIcon() {
    if (lightMode) {
      return (
        <Image
          src={"/assets/cn-logo-light.svg"}
          alt="Common ninja logo"
          width={200}
          height={30}
        />
      );
    }

    return (
      <Image
        src={"/assets/cn-logo-dark.svg"}
        alt="Common ninja logo"
        width={200}
        height={30}
      />
    );
  }

  function renderLinks() {
    return (
      <div className="links">
        <div
          className="link-wrapper"
          onMouseEnter={() => setShowAppsPopover(true)}
          onMouseLeave={() => setShowAppsPopover(false)}
        >
          <div>
            <Link
              className={cn("section-label", { show: showAppsPopover })}
              href="/widgets"
            >
              Widgets+
              <RiArrowDownSLine />
            </Link>
            <AppsPopover
              show={showAppsPopover}
              columns={columnItems}
              lightMode={lightMode}
            />
          </div>
        </div>
        <div
          className="link-wrapper"
          onMouseEnter={() => setShowFeaturesPopover(true)}
          onMouseLeave={() => setShowFeaturesPopover(false)}
        >
          <div>
            <Link
              className={cn("section-label", { show: showFeaturesPopover })}
              href="/features"
            >
              Features
              <RiArrowDownSLine />
            </Link>
            <FeaturesPopover show={showFeaturesPopover} lightMode={lightMode} />
          </div>
        </div>
        <div
          className="link-wrapper"
          onMouseEnter={() => setShowCompanyPopover(true)}
          onMouseLeave={() => setShowCompanyPopover(false)}
        >
          <div>
            <h3 className={cn("section-label", { show: showCompanyPopover })}>
              Resources <RiArrowDownSLine />
            </h3>
            <CompanyPopover show={showCompanyPopover} lightMode={lightMode} />
          </div>
        </div>
        <div
          className="link-wrapper"
          onMouseEnter={() => setShowResourcesPopover(true)}
          onMouseLeave={() => setShowResourcesPopover(false)}
        >
          <div>
            <Link
              className={cn("section-label", { show: showResourcesPopover })}
              href="https://www.commoninja.com/support"
            >
              Support <RiArrowDownSLine />
            </Link>
            <SupportPopover show={showResourcesPopover} lightMode={lightMode} />
          </div>
        </div>
        <div>
          <Link
            className="section-label"
            href={
              isBrackets
                ? "https://www.commoninja.com/embed/website/pricing/bracketsninja?mode=dark&via=commonninja"
                : "/pricing"
            }
            target={isBrackets ? "_blank" : ""}
          >
            Pricing
          </Link>
        </div>
      </div>
    );
  }

  function renderSideNav(isTop: boolean) {
    const classNames = isTop ? { "in-on-top": true } : headerClassnames;
    return (
      <>
        <StyledMiniHeader
          style={style}
          className={cn(className, {
            "add-background": addBackground,
            ...classNames,
          })}
        >
          <nav>
            <Link href={"/"} className="logo">
              {getIcon()}
            </Link>
            <GiHamburgerMenu
              onClick={() => setSideNavOpen(true)}
              fontSize={23}
              className="hamburger"
            />
          </nav>
        </StyledMiniHeader>
        <StyledAside className={cn({ opened: sideNavOpen })}>
          <div className="top">
            <Link onClick={() => setSideNavOpen(false)} href="/">
              {getIcon()}
            </Link>
          </div>
          <div className="content">
            <nav>
              <Link
                className={`${pathname === "/widgets" ? "active" : ""}`}
                onClick={() => setSideNavOpen(false)}
                href="/widgets"
              >
                Widgets+
              </Link>
              <Link
                className={`${pathname === "/platforms" ? "active" : ""}`}
                onClick={() => setSideNavOpen(false)}
                href="/platforms"
              >
                Platforms
              </Link>
              <Link
                className={`${pathname === "/features" ? "active" : ""}`}
                onClick={() => setSideNavOpen(false)}
                href="/features"
              >
                Features
              </Link>
              <Link
                className={`${pathname === "/pricing" ? "active" : ""}`}
                onClick={() => setSideNavOpen(false)}
                href={
                  isBrackets
                    ? "https://www.commoninja.com/embed/website/pricing/bracketsninja?mode=dark&via=commonninja"
                    : "/pricing"
                }
                target={isBrackets ? "_blank" : ""}
              >
                Pricing
              </Link>
              <div className="sub-items">
                <p className="title">Resources</p>
                <div className="items">
                  {companyLinks.map((l) => (
                    <Link
                      key={l.name}
                      className={`${pathname === l.url ? "active" : ""}`}
                      onClick={() => setSideNavOpen(false)}
                      href={l.url}
                      target={l.target || '_self'}
                      rel={l.rel || ''}
                    >
                      {l.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="sub-items">
                <p className="title">Support</p>
                <div className="items">
                  {support.map((l) => (
                    <Link
                      key={l.name}
                      className={`${pathname === l.url ? "active" : ""}`}
                      onClick={() => setSideNavOpen(false)}
                      href={l.url}
                      target={l.target || '_self'}
                      rel={l.rel || ''}
                    >
                      {l.name}
                    </Link>
                  ))}
                </div>
              </div>
            </nav>
          </div>
          <div className="bottom">
            <Link
              href="/login"
              onClick={() => {
                trackEvent("Website Login");
              }}
            >
              Log In
            </Link>
            <Link
              className="start-creating"
              href="/widgets"
              title="Explore Our Widgets"
              id="sidebar-main-cta"
            >
              Start creating
            </Link>
          </div>
        </StyledAside>
      </>
    );
  }

  function renderHeader(isTop: boolean) {
    const classNames = isTop ? { "in-on-top": true } : headerClassnames;

    return (
      <StyledHeader
        className={cn({
          "light-mode": lightMode,
          "add-background": addBackground,
          ...classNames,
        })}
        style={style}
      >
        <nav>
          <div className="links-divider">
            <Link href={"/"} className="logo">
              {getIcon()}
            </Link>
            {renderLinks()}
          </div>
          <div className="links-divider right-side">
            <Link
              className="login"
              href={isDeveloper ? "/developer/login" : "/login"}
              onClick={() => {
                trackEvent("Website Login");
              }}
            >
              Log In
            </Link>

            <Link
              className="start-creating"
              href="/widgets"
              title="Explore Our Widgets"
              id="header-main-cta"
            >
              Start creating
            </Link>
          </div>
        </nav>
      </StyledHeader>
    );
  }

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.pathname.includes("brackets")
    ) {
      setIsBrackets(true);
    } else {
      setIsBrackets(false);
    }
  }, []);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // Scrolling down and beyond 50px/breakPoint
      setIsVisible(false);
    } else if (currentScrollY < lastScrollY) {
      // Scrolling up
      setIsVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  if (pathname.includes('/promo/') || pathname.includes('/pages/')) {
    return null;
  }

  return (
    <>
      <ReactDimmer isOpen={sideNavOpen} exitDimmer={setSideNavOpen} blur={1} />
      {lastScrollY < heightBreakpoint && !fixedPosition && renderSideNav(true)}
      {(lastScrollY > heightBreakpoint || fixedPosition) &&
        renderSideNav(false)}

      {lastScrollY < heightBreakpoint && !fixedPosition && renderHeader(true)}
      {(lastScrollY > heightBreakpoint || fixedPosition) && renderHeader(false)}
    </>
  );
};
